<template>
  <div
    class="shadow-xl hover:shadow-lg bg-white dark:bg-slate-900 mt-2 max-h-96 scroll-py-2 overflow-y-auto text-sm text-slate-700 dark:text-slate-100 border border-solid border-slate-50 dark:border-slate-800 rounded-lg"
  >
    <div
      v-if="isLoading"
      class="font-medium text-sm text-slate-400 dark:text-slate-700 p-5"
    >
      {{ loadingPlaceholder }}
    </div>
    <ul
      v-if="shouldShowResults"
      class="custom-box-ul bg-white dark:bg-slate-900 flex flex-col text-sm text-slate-700 dark:text-slate-100"
      role="listbox"
    >
      <li
        v-for="(article, index) in items"
        :id="article.id"
        :key="article.id"
        class="hover-grey custom-box-li group flex cursor-pointer select-none items-center p-1"
        role="option"
        :class="borderActive(index)"
        tabindex="-1"
      >
        <a
          class="custom-box-li-a flex flex-col gap-1 overflow-y-hidden custom-link"
          :href="generateArticleUrl(article)"
        >
          <span
            v-dompurify-html="
              prepareContent(`${article.title} | ${article.category.name}`)
            "
            class="flex-auto truncate text-base leading-6 w-full overflow-hidden text-ellipsis whitespace-nowrap"
          />
          <span
            class="flex-auto truncate text-base leading-6 w-full overflow-hidden text-ellipsis whitespace-nowrap custom-category"
          >
            {{ portalSlug }} > {{ article.category.name }}
          </span>
        </a>
      </li>
    </ul>

    <div
      v-if="showEmptyResults"
      class="font-medium text-sm text-slate-400 dark:text-slate-700 p-5"
    >
      {{ emptyPlaceholder }}
    </div>
  </div>
</template>

<script>
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';

export default {
  mixins: [messageFormatterMixin],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    emptyPlaceholder: {
      type: String,
      default: '',
    },
    portalSlug: {
      type: String,
      default: '',
    },
    searchPlaceholder: {
      type: String,
      default: '',
    },
    loadingPlaceholder: {
      type: String,
      default: '',
    },
    resultsTitle: {
      type: String,
      default: '',
    },
    searchTerm: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedIndex: -1,
    };
  },

  computed: {
    showEmptyResults() {
      return !this.items.length && !this.isLoading;
    },
    shouldShowResults() {
      return this.items.length && !this.isLoading;
    },
  },

  methods: {
    borderActive(index) {
      return index + 1 !== this.items.length ? 'custom-box-li-border' : '';
    },
    generateArticleUrl(article) {
      return article.link;
    },
    adjustScroll() {
      this.$nextTick(() => {
        this.$el.scrollTop = 102 * this.selectedIndex;
      });
    },
    prepareContent(content) {
      return this.highlightContent(content, this.searchTerm, 'font-bold');
    },
  },
};
</script>
<style lang="css">
.custom-link {
  text-decoration: none !important;
  color: hsl(216.46deg 83.08% 46.76%) !important;
}

.custom-category {
  color: hsl(202, 6%, 38%) !important;
  font-size: 0.8rem !important;
}

.custom-box-ul {
  padding: 0px !important;
}

.custom-box-li {
  transition: background-color 0.2s !important;
}

.custom-box-li-border {
  border-bottom: 1px solid rgb(182, 177, 177) !important;
}

.hover-grey:hover {
  background-color: #dbdbdb;
  /* Fondo gris claro al pasar el mouse */
}

.custom-box-li-a {
  margin-left: 3% !important;
  margin-top: 1% !important;
  margin-bottom: 1% !important;
  width: 100% !important;
}
</style>
