<template>
  <div v-on-clickaway="closeSearch" class="max-w-5xl w-full relative my-4">
    <public-search-input
      v-model="searchTerm"
      :search-placeholder="searchTranslations.searchPlaceholder"
      :handle-enter="handleEnter"
      @focus="openSearch"
    />
    <div
      v-if="shouldShowSearchBox"
      class="absolute top-14 w-full"
      @mouseover="openSearch"
    >
      <search-suggestions
        :items="searchResults"
        :is-loading="isLoading"
        :search-term="searchTerm"
        :empty-placeholder="searchTranslations.emptyPlaceholder"
        :results-title="searchTranslations.resultsTitle"
        :loading-placeholder="searchTranslations.loadingPlaceholder"
        :portal-slug="portalSlug"
      />
    </div>
    <div v-if="isEnter" class="custom-box-result">
      <div v-if="!isLoading" class="custom-box-result-cook">
        <a :href="linkHome()">
          {{ portalSlug.split('-').join(' ') }}
        </a>
        <span> > Resultados de la busqueda </span>
      </div>
      <div v-if="isLoading" class="custom-box-result-loading">
        {{ searchTranslations.loadingPlaceholder }}
      </div>
      <div
        v-if="searchResults.length === 0 && !isLoading"
        class="custom-box-result-empty"
      >
        <h4>No hay resultados para "{{ searchTerm }}"</h4>
      </div>

      <div
        v-if="searchResults.length > 0 && !isLoading"
        class="custom-box-result-main"
      >
        <h4>{{ itemTotal }} resultados para "{{ searchTerm }}"</h4>
        <ul
          v-if="searchResults.length > 0 && !isLoading"
          class="custom-box-result-ul"
          role="listbox"
        >
          <li
            v-for="article in searchResults"
            :id="article.id"
            :key="article.id"
            class="custom-box-result-li"
            role="option"
            tabindex="-1"
          >
            <div>
              <h4>
                {{ portalSlug.split('-').join(' ') }} >
                {{ article.category.name }}
              </h4>
            </div>
            <div class="custom-box-result-li-div">
              <a :href="article.link">
                <span
                  v-dompurify-html="
                    prepareContent(
                      `${article.title} | ${article.category.name}`
                    )
                  "
                  class=""
                />
              </a>
            </div>
            <div>
              <h3
                v-dompurify-html="
                  prepareContentFragment(`${article.content}`, article.link)
                "
              />
            </div>
          </li>
        </ul>
        <div v-if="pageTotal > 0" class="btn-next">
          <button
            v-if="pageSearch > 1"
            class="btn-next-left"
            @click="pageRemove"
          >
            <span>Anterior</span>
          </button>
          <button
            v-if="pageSearch < pageTotal"
            class="btn-next-rigth"
            @click="pageAdd"
          >
            <span>Siguiente</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchSuggestions from './SearchSuggestions.vue';
import PublicSearchInput from './PublicSearchInput.vue';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import ArticlesAPI from '../api/article';

export default {
  components: {
    PublicSearchInput,
    SearchSuggestions,
  },
  mixins: [messageFormatterMixin],
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      isEnter: false, // temporal es false
      searchTerm: '',
      isLoading: false,
      showSearchBox: false,
      searchResults: [],
      pageSearch: 0,
      pageTotal: 0,
      searchResultsTotal: [],
      itemTotal: 0,
    };
  },

  computed: {
    portalSlug() {
      return window.portalConfig.portalSlug;
    },
    localeCode() {
      return window.portalConfig.localeCode;
    },
    shouldShowSearchBox() {
      return this.searchTerm !== '' && this.showSearchBox && !this.isEnter;
    },
    searchTranslations() {
      const { searchTranslations = {} } = window.portalConfig;
      return searchTranslations;
    },
  },

  watch: {
    searchTerm() {
      this.isEnter = false;
      if (this.typingTimer) {
        clearTimeout(this.typingTimer);
      }
      const box = document.getElementById('box-category');
      if (box.classList.contains('hidden')) {
        // Si está presente, quitarla para mostrar el elemento
        box.classList.remove('hidden');
      }

      this.openSearch();
      this.isLoading = true;
      this.typingTimer = setTimeout(() => {
        this.fetchArticlesByQuery();
      }, 1000);
    },
    currentPage() {
      this.clearSearchTerm();
    },
  },

  beforeDestroy() {
    clearTimeout(this.typingTimer);
  },

  methods: {
    pageAdd() {
      this.pageSearch += 1;
      this.searchResults = this.searchResultsTotal[this.pageSearch - 1];
    },
    pageRemove() {
      this.pageSearch -= 1;
      this.searchResults = this.searchResultsTotal[this.pageSearch - 1];
    },
    splitArray(array, chunkSize) {
      // Initialize an empty array to hold the chunks
      const result = [];
      // Loop through the array and create chunks of the specified size
      for (let i = 0; i < array.length; i += chunkSize) {
        // Slice the array from the current index to the index + chunk size
        const chunk = array.slice(i, i + chunkSize);
        // Push the chunk into the result array
        result.push(chunk);
      }
      return result;
    },
    extractTextFromHTML(html) {
      // Create a temporary DOM element
      const tempDiv = document.createElement('div');

      // Set the HTML string as the innerHTML of the temporary element
      tempDiv.innerHTML = html;

      // Retrieve the text content from the temporary element
      const text = tempDiv.textContent || tempDiv.innerText || '';

      return text;
    },
    showFragment(text, term) {
      const maxChar = 70;
      const textLower = text.toLowerCase();
      const termLower = term.toLowerCase();
      const index = textLower.indexOf(termLower);

      // Si no se encuentra el término, retorna el inicio hasta la última palabra completa
      if (index === -1) {
        const fragment = text.substring(0, maxChar);
        const lastSpaceIndex = fragment.lastIndexOf(' ');
        return lastSpaceIndex === -1
          ? fragment + '...'
          : text.substring(0, lastSpaceIndex) + '...';
      }

      // Calcula los límites iniciales
      let start = Math.max(0, index - maxChar);
      let end = Math.min(text.length, index + term.length + maxChar);

      // Ajusta el inicio para comenzar en una palabra completa
      if (start > 0) {
        start = text.lastIndexOf(' ', start) + 1;
        if (start < 0) start = 0;
      }

      // Ajusta el final para terminar en una palabra completa
      if (end < text.length) {
        const lastSpaceIndex = text.lastIndexOf(' ', end);
        end = lastSpaceIndex === -1 ? end : lastSpaceIndex;
      }

      // Agrega puntos suspensivos al inicio si no empieza desde el principio
      const prefix = start > 0 ? '... ' : '';
      // Agrega puntos suspensivos al final si no termina en el final del texto
      const suffix = end < text.length ? ' ...' : '';

      return prefix + text.substring(start, end) + suffix;
    },
    prepareContent(content) {
      return this.highlightContent(content, this.searchTerm, 'font-bold');
    },
    prepareContentFragment(content, link) {
      const textHtml = this.extractTextFromHTML(content);
      const newContent = this.showFragment(textHtml, this.searchTerm);
      return (
        this.highlightContent(newContent, this.searchTerm, 'font-bold') +
        `
      <a href="${link}"><span class="custom-box-result-li-a">
                    Leer más
                  </span></a>
      `
      );
    },
    linkHome() {
      return `/hc/${this.portalSlug}`;
    },
    async handleEnter() {
      this.isEnter = true;
      await this.fetchArticlesByQuery(800);
      if (this.searchResults.length > 5) {
        this.searchResultsTotal = this.splitArray(this.searchResults, 5);
        this.searchResults = this.searchResultsTotal[0];
        this.pageSearch = 1;
        this.pageTotal = this.searchResultsTotal.length;
      }
      document.getElementById('box-category').classList.add('hidden');
      // Aquí puedes añadir la lógica adicional que necesites al presionar Enter.
    },
    onChange(e) {
      this.$emit('input', e.target.value);
    },
    onBlur(e) {
      this.$emit('blur', e.target.value);
    },
    openSearch() {
      this.showSearchBox = true;
    },
    closeSearch() {
      this.showSearchBox = false;
    },
    clearSearchTerm() {
      this.searchTerm = '';
    },
    async fetchArticlesByQuery(limit = 6) {
      try {
        this.searchResults = [];
        const { data } = await ArticlesAPI.searchArticles(
          this.portalSlug,
          this.localeCode,
          this.searchTerm,
          true,
          limit
        );
        this.searchResults = data.payload;
        this.itemTotal = (this.searchResults || []).length;
        this.isLoading = true;
      } catch (error) {
        // Show something wrong message
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="css">
.custom-box-result {
  background-color: white !important;
  margin-top: 4% !important;
  width: 100% !important;
  height: 50rem !important;
}

.custom-box-result-loading {
  text-align: center !important;
  padding-top: 5rem;
  font-size: 25px;
  font-weight: 600;
  color: #00377d !important;
}

.custom-box-result-empty {
  margin-top: 1rem;
}

.custom-box-result-empty h4 {
  text-align: center !important;
  font-size: 18px;
  font-weight: 600;
  color: #00377d !important;
}

.custom-box-result-main {
  margin-top: 1rem;
}

.custom-box-result-main h4 {
  text-align: left !important;
  font-size: 18px;
  font-weight: 600;
  color: #00377d !important;
  margin-left: 6%;
}

.custom-box-result-cook a {
  text-decoration: none !important;
  outline: none !important;
  cursor: pointer !important;
  color: #1f73b7 !important;
  font-weight: 600;
}

.custom-box-result-cook span {
  color: grey;
  font-size: smaller;
}

.custom-box-result-cook {
  padding: 15px !important;
}

.hidden {
  display: none;
}

.custom-box-result-ul {
  background: #fff !important;
  border-radius: 8px !important;
  padding-left: 9% !important;
  padding-right: 2%;
  padding-top: 2%;
}

.custom-box-result-li {
  padding: 20px 0 !important;
  border-bottom: 1px solid rgba(0, 55, 125, 0.15) !important;
}

.custom-box-result-li h4 {
  background: rgba(0, 55, 125, 0.11) !important;
  padding: 2px 8px !important;
  border-radius: 6px !important;
  display: inline !important;
  color: #666 !important;
  font-size: 12px !important;
  margin-left: 0% !important;
}

.custom-box-result-li a {
  font-weight: 300 !important;
  color: #00377d !important;
  text-decoration: none !important;
  outline: none !important;
  cursor: pointer !important;
}

.custom-box-result-li-div {
  padding-top: 2% !important;
  padding-bottom: 2% !important;
}

.custom-box-result-li-a {
  color: #00c8ff !important;
  text-decoration: none !important;
  outline: none !important;
  cursor: pointer !important;
}

.btn-next {
  text-align: center !important;
  padding-top: 2% !important;
  padding-bottom: 1% !important;
}

.btn-next-left {
  padding-right: 2% !important;
  background-color: #00c8ff !important;
  color: #fff !important;
  padding: 1% !important;
  border: none !important;
  border-radius: 24px !important;
}

.btn-next-left:hover {
  background-color: #198afd !important;
}

.btn-next-rigth:hover {
  background-color: #198afd !important;
}

.btn-next-rigth {
  background-color: #00c8ff !important;
  color: #fff !important;
  padding: 1% !important;
  border: none !important;
  border-radius: 24px !important;
}
</style>
