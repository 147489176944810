import axios from 'axios';

class ArticlesAPI {
  constructor() {
    this.baseUrl = '';
  }

  searchArticles(portalSlug, locale, query, truncate = false, limit = null) {
    let baseUrl = `${this.baseUrl}/hc/${portalSlug}/${locale}/articles.json?query=${query}`;
    if (truncate) {
      baseUrl = `${baseUrl}&truncate=true`;
    }
    if (limit) {
      baseUrl = `${baseUrl}&limit=${limit}`;
    }
    return axios.get(baseUrl);
  }
}

export default new ArticlesAPI();
